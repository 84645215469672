class orderItemsSelectComponent extends Component {

    static name() {
        return "orderItemsSelect";
    }

    static componentName() {
        return "orderItemsSelect";
    }

    data() {
        return {
            orderSelected:null,
        };
    }


    getComputed() {
        return {
            currentSelected:function(){
                if(!this.orderSelected)
                    this.orderSelected=this.$store.getters.getOrderSelected;
                return this.$store.getters.getOrderSelected;
            },
            options:function(){
                return this.$store.getters.getSettingItemOrdered;
            }
        };
    }

    getMethods() {
        return {
            emitEvent:this.emitEvent
        };
    }

    emitEvent(){
        this.$store.dispatch('reorderItems',this.orderSelected);
        this.$emit('reorder');
    }

    getTemplate() {
        return `<div class="form-inline">
                   <label class="mr-1">{{tr('Order by')}} :</label>
                    <select id="soflow" v-model="orderSelected" class="form-control custom-select" @change="emitEvent">
                        <template  v-if="$store.getters.getSetting!=null" v-for="orderField of options">
                            <template v-if="orderField.fields.internalId==currentSelected">
                                <option :value="orderField.fields.internalId" selected="selected" >
                                    <p>
                                        {{tr(orderField.fields.ItemField)}} ({{orderField.fields.OrderDirection}})
                                        <template v-if="orderField.fields.OrderDirection=='asc'">
                                           <h5> &uarr;</h5>
                                        </template>
                                        <template v-else>
                                           <h5> &darr;</h5>
                                        </template>
                                    </p>
                                </option>
                             </template>
                             <template v-else>
                                <option :value="orderField.fields.internalId">
                                    <p>{{tr(orderField.fields.ItemField)}} ({{orderField.fields.OrderDirection}})
                                        <template v-if="orderField.fields.OrderDirection=='asc'">
                                           <h5> &uarr;</h5>
                                        </template>
                                        <template v-else>
                                           <h5> &darr;</h5>
                                        </template>
                                    </p>
                                </option>
                             </template>
                        </template>
                    </select>
                </div>`;
    }
}

orderItemsSelectComponent.registerComponent();